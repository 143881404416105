import * as FullStory from '@fullstory/browser';

import { ENV, Environment } from 'config/baseConfig';

export const setup = (): void =>
  FullStory.init({
    orgId: 'o-1DJB6Z-na1',
    devMode: ENV !== Environment.PROD && ENV !== Environment.STAGING
  });

export const identify = (user: any): void => {
  const isProdOrStaging = ENV === Environment.PROD || ENV === Environment.STAGING;
  if (isProdOrStaging && FullStory.isInitialized()) {
    FullStory.identify(user.id, {
      firstName: user.firstName,
      lastName: user.lastName,
      userRole: user.userRole,
      email: user.email,
      company_id: user.company.id,
      company_name: user.company.name
    });
  }
};
