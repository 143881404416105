import gql from 'graphql-tag';

export const UPDATE_VIEW_FILTERS = gql`
  mutation updateViewFilters(
    $timeframe: String
    $startDate: DateTime
    $endDate: DateTime
    $eventFormats: [String]
    $timezoneCodes: [String]
    $categories: [Int]
    $locations: [Int]
    $teams: [Int]
  ) {
    updateViewFilters(
      input: {
        timeframe: $timeframe
        startDate: $startDate
        endDate: $endDate
        eventFormats: $eventFormats
        timezoneCodes: $timezoneCodes
        categories: $categories
        locations: $locations
        teams: $teams
      }
    ) {
      ok
    }
  }
`;

export const UPDATE_VIEW_TABLE_VISIBILITY_MUTATION = gql`
  mutation UpdateViewTableVisibility($input: UpdateViewTableVisibilityInput!) {
    updateViewTableVisibility(input: $input) {
      ok
      message
      showHeatmapColor
    }
  }
`;

export interface UpdateViewTableVisibilityResponse {
  updateViewTableVisibility: {
    ok: boolean;
    message: string;
    showHeatmapColor?: boolean;
  };
}

export interface UpdateViewTableVisibilityVariables {
  input: {
    eventTableColumnVisibility?: { key: string; visible: boolean; sort: number }[];
    showHeatmapColor?: boolean;
  };
}
