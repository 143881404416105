import React, { createContext } from 'react';

/**
 * TS Types
 */

interface EventInvitationJobsContextType {
  eventIds: Set<number>;
  markEventJobAsStarted: (eventId: number) => void;
  markEventJobAsCompleted: (eventId: number) => void;
}

/**
 * Context to manage the event invitation jobs state
 */

const EventInvitationJobsContext = createContext<EventInvitationJobsContextType>({
  eventIds: new Set(),
  markEventJobAsStarted: () => undefined,
  markEventJobAsCompleted: () => undefined
});

export default EventInvitationJobsContext;

/**
 * Provider to manage the event invitation jobs state
 */
export const EventInvitationJobsProvider: React.FC<React.PropsWithChildren<unknown>> = ({
  children
}) => {
  /**
   * An index of event IDs to their respective job states
   */
  const [eventIds, setEventIds] = React.useState<Set<number>>(new Set());

  /**
   * Mark an event job as started
   *
   * @param eventId Event ID to mark as started
   */
  const markEventJobAsStarted = (eventId: number): void => {
    if (eventIds.has(eventId)) {
      return;
    }
    setEventIds((prevEventIds) => {
      const newEventIds = new Set(prevEventIds);
      newEventIds.add(eventId);
      return newEventIds;
    });
  };

  /**
   * Mark an event job as completed
   *
   * @param eventId Event ID to mark as completed
   */
  const markEventJobAsCompleted = (eventId: number): void => {
    setEventIds((prevEventIds) => {
      const newEventIds = new Set(prevEventIds);
      newEventIds.delete(eventId);
      return newEventIds;
    });
  };

  return (
    <EventInvitationJobsContext.Provider
      value={{ eventIds, markEventJobAsStarted, markEventJobAsCompleted }}
    >
      {children}
    </EventInvitationJobsContext.Provider>
  );
};

/**
 * Custom hook to access and manage the event invitation processing state
 */
export const useEventInvitationState = (
  eventId: number
): {
  eventHasInvitationBeingProcessed: boolean;
  markEventJobAsStarted: () => void;
  markEventJobAsCompleted: () => void;
} => {
  const ctx = React.useContext(EventInvitationJobsContext);

  if (!ctx) {
    throw new Error('useEventInvitationState must be used within an EventInvitationJobsProvider');
  }

  return {
    eventHasInvitationBeingProcessed: ctx.eventIds.has(eventId),
    markEventJobAsStarted: () => ctx.markEventJobAsStarted(eventId),
    markEventJobAsCompleted: () => ctx.markEventJobAsCompleted(eventId)
  };
};
