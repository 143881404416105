import React from 'react';
import { createRoot } from 'react-dom/client';

import App from 'app';

import { setupSentry } from './lib/sentry';
import { setup as setupFullStory } from './lib/fullstory';

import 'styles/custom.scss';
import 'styles/ql-editor-content-styles.scss';

// Initialize Sentry logging
setupSentry();
setupFullStory();

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(<App />);
