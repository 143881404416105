import { ApolloClient, ApolloLink, InMemoryCache, from } from '@apollo/client';
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev';
import { onError } from '@apollo/client/link/error';
import { createUploadLink } from 'apollo-upload-client';
import queryString from 'query-string';
import omitDeep from 'omit-deep-lodash';

import { getConfig, ENV, Environment } from 'config/baseConfig';

// List of GQL_ERRORs that will be handled separately.
export enum GQL_ERRORS {
  UNAUTHORIZED = 'Unauthorized',
  ACCESS_DENIED = 'Access Denied',
  NOT_FOUND = 'Not Found'
}

if (ENV === Environment.DEV) {
  // Adds messages only in a dev environment
  loadDevMessages();
  loadErrorMessages();
}

const httpLink = createUploadLink({
  uri: getConfig('GRAPHQL_URL'),
  credentials: getConfig('APOLLO_COOKIE_RULE')
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      // Why is this needed???
      console.error(`[GQL Error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      return handleNetworkError(message);
    });
  } else if (networkError) {
    console.error(`[Network Error]: ${networkError}`, { networkError });
  }
});

const handleNetworkError = (message: string): void => {
  if (message === GQL_ERRORS.UNAUTHORIZED) {
    const { forceLoginOptions, ...extraParams } = queryString.parse(window.location.search);
    // In the case that the last request is unauthorized and we pass a nextUrl through to the sign in page;
    // Escape any URL parameter symbols in the nextURL itself, so we don't read the symbols as delimiters
    let params = '';
    if (extraParams && Object.keys(extraParams).length > 0) {
      params += `%3f${Object.keys(extraParams)
        .map((key) => `${key}=${extraParams[key]}`)
        .join('%26')}`;
    }
    if (window.location.pathname.startsWith('/signin')) {
      window.location.href = window.location.href; // eslint-disable-line no-self-assign
    } else {
      window.location.href = `/signin?nextUrl=${window.location.pathname}${params}${
        forceLoginOptions ? '&forceLoginOptions=true' : ''
      }`;
      return;
    }
  }

  // Redirect user to error page.
  if (message === GQL_ERRORS.ACCESS_DENIED) {
    window.location.href = `/error?code=${GQL_ERRORS.ACCESS_DENIED}`;
    return;
  }

  if (message === GQL_ERRORS.NOT_FOUND) {
    window.location.href = `/error?code=${GQL_ERRORS.NOT_FOUND}`;
    /* eslint-disable no-useless-return */
    return;
  }
};

/**
 * A custom Apollo middleware to clean up `__typename` from variables/payload before sending it to the BE.
 * The `omitDeep` utility ensures that values that are other than Objects and Array are not changed (e.g. Date, File, Number, etc.)
 *
 * Ideally, we should be more explicit about the fields to be sent to the BE, but this is a quick fix for now.
 */
const cleanUpTypeNameFromVariablesMiddleware = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    // eslint-disable-next-line no-param-reassign
    operation.variables = omitDeep(operation.variables, '__typename');
  }

  return forward(operation);
});

// https://www.apollographql.com/docs/react/api/link/introduction/#composing-a-link-chain
const link = from([
  // Middleware link(s) must be at the beginning of the chain.
  cleanUpTypeNameFromVariablesMiddleware,
  errorLink,
  // Terminating link(s) must be at the end of the chain.
  httpLink
]);

const cache = new InMemoryCache({
  typePolicies: {
    Guest: {
      keyFields: ['email']
    },
    GuestUser: {
      keyFields: ['eventId', 'userId']
    }
  }
});

const client = new ApolloClient({
  link,
  cache,
  defaultOptions: {
    // Setting `notifyOnNetworkStatusChange` to true globally to resume behavior from Apollo Client 3.7.x and earlier.
    // See https://github.com/apollographql/apollo-client/issues/11151 for more information
    query: {
      notifyOnNetworkStatusChange: true
    },
    watchQuery: {
      notifyOnNetworkStatusChange: true
    }
  }
});

export default client;
