import React, { createContext, FunctionComponent } from 'react';
import { useQuery } from '@apollo/client';
import * as Sentry from '@sentry/react';
import { Navigate } from 'react-router';
import { Outlet } from 'react-router-dom';

import { SIGN_IN_ROUTE } from 'constants/routes';
import LoadingSpinner from 'components/common/loaders/LoadingSpinner';
import { GLOBAL_USER_QUERY } from 'graphql/queries/user/user';
import { UserContextType } from 'types/user.types';
import { identify } from 'lib/fullstory';

const UserContext = createContext<UserContextType | undefined>(undefined);

export const UserProvider: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const { loading, data, error, refetch } = useQuery(GLOBAL_USER_QUERY, {
    fetchPolicy: 'network-only'
  });

  if (loading) {
    return <LoadingSpinner fullscreen />;
  }

  if (error || !data) {
    console.error(error);
  }

  const user = data?.globalUser;

  if (!user) {
    console.error('global user not detected');
    return <Navigate to={SIGN_IN_ROUTE} />;
  }

  Sentry.setUser(user);
  identify(user);

  return (
    <UserContext.Provider value={{ ...user, refetchUser: refetch }}>
      <Outlet />
    </UserContext.Provider>
  );
};

export default UserContext;
