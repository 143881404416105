export enum Environment {
  DEV = 'development',
  STAGING = 'staging',
  E2E = 'e2e',
  PROD = 'production'
}

/* eslint-disable max-len */
export const getEnvironment = (): Environment => {
  if (process.env.NODE_ENV === 'development') {
    return Environment.DEV;
  }
  if (process.env.TARGET_ENV === 'staging') {
    return Environment.STAGING;
  }

  if (process.env.TARGET_ENV === 'e2e') {
    return Environment.E2E;
  }

  return Environment.PROD;
};

export const ENV = getEnvironment();

// List of base config.
const baseConfig: { [key: string]: { [key: string]: string } } = {
  // Graphql endpoint.
  GRAPHQL_URL: {
    staging: 'https://api-stage.epochapp.com/graphql',
    e2e: 'https://api-e2e.epochapp.com/graphql',
    development: 'http://localhost:5000/graphql',
    production: '/graphql',
    default: 'http://localhost:5000/graphql'
  },
  // API endpoint.
  API_URL: {
    staging: 'https://api-stage.epochapp.com/api/v1',
    e2e: 'https://api-e2e.epochapp.com/api/v1',
    default: '/api/v1'
  },
  // WS endpoint.
  WS: {
    production: `https://${window.location.hostname}`,
    staging: 'https://api-stage.epochapp.com',
    e2e: 'https://api-e2e.epochapp.com',
    development: 'http://localhost:5000',
    default: window.location.hostname
  },
  // Apollo cookie rule to accept cookie setter from backend.
  APOLLO_COOKIE_RULE: {
    staging: 'include',
    e2e: 'include',
    development: 'include',
    production: 'same-origin',
    default: 'include'
  }
};

/**
 * Grab config value.
 * @param {*} key
 * @returns
 */
export const getConfig = (key: string): string => {
  if (ENV && baseConfig[key][ENV]) {
    return baseConfig[key][ENV];
  }

  return baseConfig[key].default;
};
