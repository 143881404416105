import React, { FunctionComponent } from 'react';

import SpinningLoader from 'assets/images/SpinningLoader.svg';

export interface LoadingSpinnerProps {
  fullscreen?: boolean;
}

const LoadingSpinner: FunctionComponent<React.PropsWithChildren<LoadingSpinnerProps>> = ({
  fullscreen = false
}): JSX.Element => {
  return (
    <div
      data-testid="loadingSpinner"
      className={`tw-flex tw-flex-1 ${
        fullscreen ? 'tw-top-0 tw-h-screen' : 'tw-relative'
      } tw-z-30 tw-w-full tw-items-center tw-justify-center tw-bg-white`}
    >
      <SpinningLoader />
    </div>
  );
};

export default LoadingSpinner;
