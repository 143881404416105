/**
 * Ensures that the page is scrolled to the top when the route changes
 * https://v5.reactrouter.com/web/guides/scroll-restoration/scroll-to-top
 */
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const ScrollToTop = (): null => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default ScrollToTop;
