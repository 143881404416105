import React, { useEffect, useState, useMemo, FunctionComponent } from 'react';

import ToasterContext, { ToasterConfig, ToasterVariant } from 'context/toasterContext';

import CloseIcon from '../icons/CloseIcon';

import * as Styled from './Toaster.styled';

/**
 * Toaster component.
 * Wraps the entire app and inits 'toaster' context.
 * Depending on 'toaster' context value, renders out the pop-up.
 * @param param0
 * @returns
 */
const Toaster: FunctionComponent<React.PropsWithChildren<unknown>> = ({ children }) => {
  const [triggerOutAnimation, setTriggerOutAnimation] = useState(false);

  const [toaster, setToaster] = useState<ToasterConfig>({
    text: '',
    variant: ToasterVariant.DEFAULT,
    triggerHide: false,
    keepOpen: false
  });

  const toasterValue = useMemo(() => {
    const isToasterOpen = toaster.text !== undefined && toaster.text !== '';
    return { toaster, setToaster, isToasterOpen };
  }, [toaster.text]);

  /**
   * Register fade out animation to container.
   */
  useEffect(() => {
    let timerID: ReturnType<typeof setTimeout>;
    if (toaster.text && !toaster.keepOpen) {
      timerID = setTimeout(() => {
        setTriggerOutAnimation(true);
      }, 6000);
    }

    return (): void => clearTimeout(timerID);
  }, [toaster.text]);

  // Manually trigger hide animation based on context value.
  useEffect(() => {
    if (toaster.triggerHide) {
      setTriggerOutAnimation(true);
    }
  }, [toaster.triggerHide]);

  // Reset states after fadeout animation was triggered.
  useEffect(() => {
    let timerID2: ReturnType<typeof setTimeout>;

    if (triggerOutAnimation) {
      timerID2 = setTimeout(() => {
        setToaster({ ...toaster, text: '' });
        setTriggerOutAnimation(false);
      }, 500);
    }

    return (): void => clearTimeout(timerID2);
  }, [triggerOutAnimation]);

  return (
    <ToasterContext.Provider value={toasterValue}>
      {toaster.text && (
        <Styled.Notify offSetLeft={toaster.offsetLeft}>
          <Styled.NotifyAnimationContainer
            out={triggerOutAnimation}
            error={toaster.variant === ToasterVariant.ERROR}
          >
            <div>{toaster.text}</div>

            <Styled.CloseButtonWrapper
              onClick={(): void => {
                setTriggerOutAnimation(true);
              }}
            >
              <CloseIcon color="white" width={16} height={16} />
            </Styled.CloseButtonWrapper>
          </Styled.NotifyAnimationContainer>
        </Styled.Notify>
      )}
      {children}
    </ToasterContext.Provider>
  );
};

export default Toaster;
