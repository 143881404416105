import { useState, useEffect, useRef } from 'react';
import { useQuery, useMutation } from '@apollo/client';

import { useToaster, ToasterVariant } from 'hooks/useToaster';
import {
  UPDATE_VIEW_TABLE_VISIBILITY_MUTATION,
  UpdateViewTableVisibilityResponse,
  UpdateViewTableVisibilityVariables
} from 'graphql/mutations/insights/insights.mutation';
import {
  INSIGHTS_DASHBOARD_EVENTS,
  InsightsDashboardEventsResults
} from 'graphql/queries/insights/insights.query';

interface EventsTableFilters {
  startDate: Date | null;
  endDate: Date | null;
  teams: string[];
  categories: string[];
  locations: string[];
  timezones: string[];
  formats: string[];
}

export interface EventsTableState {
  data?: InsightsDashboardEventsResults['eventsInsights']['items'];
  dataTotal: InsightsDashboardEventsResults['eventsInsights']['total'];
  dataPages?: InsightsDashboardEventsResults['eventsInsights']['pages'];
  dataCurrentPage: number;
  dataResultsPerPage: number;
  loading: boolean;
  refetch: (
    variables?: Partial<EventsTableFilters> & { sortBy?: string; sortOrder?: string }
  ) => void;
  setSortBy: (page: string) => void;
  setSortOrder: (page: string) => void;
  handleChangePage: (page: number) => void;
  handleUpdateViewTableVisibility: (input: any) => void;
}

export default function useEventsTable({
  startDate,
  endDate,
  teams,
  categories,
  locations,
  formats,
  timezones
}: EventsTableFilters): EventsTableState {
  const resultsPerPage = 10;

  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState<string>('date');
  const [sortOrder, setSortOrder] = useState<string>('desc');
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<
    InsightsDashboardEventsResults['eventsInsights']['items'] | undefined
  >(undefined);
  const [dataTotal, setDataTotal] = useState<number>(0);
  const { showToast } = useToaster();

  const isFirstRender = useRef(true);

  const {
    data: queryData,
    loading: queryLoading,
    refetch
  } = useQuery<InsightsDashboardEventsResults>(INSIGHTS_DASHBOARD_EVENTS, {
    fetchPolicy: 'network-only',
    variables: {
      startDate,
      endDate,
      teams,
      categories,
      locations,
      formats,
      timezones,
      page: 0,
      perPage: resultsPerPage,
      sortBy,
      sortOrder
    },
    skip: !startDate && !endDate
  });

  const [updateViewTableVisibility] = useMutation<
    UpdateViewTableVisibilityResponse,
    UpdateViewTableVisibilityVariables
  >(UPDATE_VIEW_TABLE_VISIBILITY_MUTATION, {
    onError: (err) => {
      console.error(err);
      showToast('Could not update metrics visibility.', {
        variant: ToasterVariant.ERROR
      });
    }
  });

  useEffect(() => {
    // when any of the filters change we reset to the first page
    setCurrentPage(1);
  }, [startDate, endDate, teams, categories, locations, formats, timezones, sortBy, sortOrder]);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (queryLoading) {
      if (isFirstRender.current) {
        setLoading(true);
      } else {
        timeout = setTimeout(() => {
          setLoading(true);
        }, 1000);
      }
    } else {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
      setLoading(false);
      if (queryData?.eventsInsights) {
        setData(queryData?.eventsInsights?.items);
        setDataTotal(queryData?.eventsInsights?.total || 0);
      }
    }

    isFirstRender.current = false;

    return () => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
    };
  }, [queryLoading, queryData?.eventsInsights?.items, queryData?.eventsInsights?.total]);

  const handleUpdateViewTableVisibility = (
    input: UpdateViewTableVisibilityVariables['input']
  ): void => {
    updateViewTableVisibility({ variables: { input } });
  };

  const handleChangePage = (page: number): void => {
    setCurrentPage(page);
    refetch({ page: page - 1 });
  };

  return {
    // values
    data,
    dataTotal,
    dataCurrentPage: currentPage,
    dataPages: queryData?.eventsInsights?.pages,
    dataResultsPerPage: resultsPerPage,
    // flags
    loading,
    // handlers
    refetch,
    setSortBy,
    setSortOrder,
    handleChangePage,
    handleUpdateViewTableVisibility
  };
}
