import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/react';

import { ENV, Environment } from 'config/baseConfig';

const SENTRY_DSN = 'https://a24be7b2d21945f8b622478f0342d400@o866882.ingest.sentry.io/5823521';
const SENTRY_TRACES_SAMPLE_RATE = 0.05;

export const setupSentry = (): void => {
  if (ENV === Environment.PROD || ENV === Environment.STAGING) {
    Sentry.init({
      dsn: SENTRY_DSN,
      environment: ENV,
      integrations: [new BrowserTracing()],
      tracesSampleRate: SENTRY_TRACES_SAMPLE_RATE
    });
  }
};
