import { useEffect, useMemo, useState } from 'react';
import { useLazyQuery } from '@apollo/client';

import { Timeframe } from '@epoch-components/DateRangePicker/utils/presets';
import {
  INSIGHTS_DASHBOARD_CHART,
  InsightsDashboardChartResult
} from 'graphql/queries/insights/insights.query';
import { ChartsData, InsightsDashboardChart } from 'types/insights-dashboard.types';
import useFeatureFlag from 'hooks/useFeatureFlag';
import { INSIGHTS_METRICS_CHARTS } from 'constants/featureFlags';

interface ChartsFilters {
  charts: InsightsDashboardChart[];
  startDate: Date | null;
  endDate: Date | null;
  timeframe: Timeframe | null;
  teams: string[];
  categories: string[];
  locations: string[];
}

export interface ChartsState {
  charts: InsightsDashboardChart[];
  data: ChartsData;
  loading: ChartsLoading;
  error: ChartsError;
}

interface ChartsLoading {
  [key: InsightsDashboardChart['id']]: boolean;
}

interface ChartsError {
  [key: InsightsDashboardChart['id']]: boolean;
}

export default function useCharts({
  charts,
  startDate,
  endDate,
  timeframe,
  teams,
  categories,
  locations
}: ChartsFilters): ChartsState {
  const [chartsData, setChartsData] = useState<ChartsData>({});
  const [chartsLoading, setChartsLoading] = useState<ChartsLoading>({});
  const [chartsError, setChartsError] = useState<ChartsError>({});

  const isChartsFeatureEnabled = useFeatureFlag(INSIGHTS_METRICS_CHARTS);

  const [getChartData] = useLazyQuery<InsightsDashboardChartResult>(INSIGHTS_DASHBOARD_CHART);

  const chartIds = useMemo(() => charts?.map((chart) => chart.id), [charts]);

  async function loadChart(chartId: number): Promise<void> {
    if (!isChartsFeatureEnabled) {
      return;
    }

    setChartsError((prevErrors) => ({ ...prevErrors, [chartId]: false }));
    setChartsLoading((prevLoading) => ({ ...prevLoading, [chartId]: true }));

    try {
      const result = await getChartData({
        variables: {
          id: `${chartId}`,
          startDate,
          endDate,
          timeframe,
          teams,
          categories,
          locations
        }
      });

      if (!result || !result.data || !result.data.insightsChart) {
        throw new Error(`No data returned for chartId: ${chartId}`);
      }

      const { id, data } = result.data.insightsChart;

      const chart = charts.find((c) => c.id === id);
      if (!chart) {
        throw new Error(`Could not find chart with id: ${id}`);
      }

      const formattedData = data.map((tick) => ({
        [!chart.groupBy ? 'date' : chart.groupBy]: tick.pX,
        [chart.metrics[0]]: tick.pY,
        ...(chart.type === 'comparison' ? { [chart.metrics[1]]: tick.sY } : {})
      }));

      setChartsData((prevData) => ({ ...prevData, [id]: formattedData }));
    } catch (error) {
      console.error(`Error loading chart data for chartId: ${chartId}`, error);
      setChartsError((prevErrors) => ({ ...prevErrors, [chartId]: true })); // Set error state for specific chart
    } finally {
      setChartsLoading((prevLoading) => ({ ...prevLoading, [chartId]: false })); // Ensure loading state is reset in all cases
    }
  }

  useEffect(() => {
    if (chartIds && chartIds.length > 0) {
      const chartsNeeded = chartIds.filter((chartId) => !chartsData[chartId]);
      Promise.all(chartsNeeded.map((chartId) => loadChart(chartId)));
    }
  }, [isChartsFeatureEnabled, chartIds]);

  useEffect(() => {
    if (chartIds && chartIds.length > 0) {
      Promise.all(chartIds.map((chartId) => loadChart(chartId)));
    }
  }, [isChartsFeatureEnabled, startDate, endDate, timeframe, teams, categories, locations]);

  return { charts, data: chartsData, loading: chartsLoading, error: chartsError };
}
