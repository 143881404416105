import { useState } from 'react';

import type { Modals } from 'constants/modals';

export interface Modal {
  key: Modals | null;
  modalData?: Record<string, any> | null;
}

export interface ModalState {
  modal: Modals | null;
  modalData?: Record<string, any> | null;
  modalStack: Modal[];
  createFromModalStack: (stack: Modal[]) => void;
  setActiveModal: (modalName: Modals, data?: Record<string, any>) => void;
  pushModal: (modalName: Modals, data?: Record<string, any>) => void;
  popModal: (updatedData?: Record<string, any>) => void;
  updateModalData: (updatedData: Record<string, any>) => void;
  clearModal: () => void;
}

export const useModal = (): ModalState => {
  const [modal, setModal] = useState<Modals | null>(null);
  const [modalData, setModalData] = useState<Record<string, any> | null>(null);
  const [modalStack, setModalStack] = useState<Modal[]>([]);

  const setActiveModal = (modalName: Modals, data?: Record<string, any>): void => {
    setModal(modalName);
    setModalData(data || {});
    setModalStack([]);
  };

  const createFromModalStack = (stack: Modal[]): void => {
    setModalStack(stack);

    const lastModalInStack = stack.pop();
    setModal(lastModalInStack?.key || null);
    setModalData(lastModalInStack?.modalData || {});
  };

  const pushModal = (modalName: Modals, data?: Record<string, any>): void => {
    // need to protect for when pushModal is called and there is no current modal set
    if (!modal) {
      setActiveModal(modalName, data);
      return;
    }

    // push the current modal onto the stack before replacing it with the new one
    const currentModal: Modal = { key: modal, modalData };
    setModalStack([...modalStack, currentModal]);

    setModal(modalName);
    setModalData(data || {});
  };

  const popModal = (updatedData?: Record<string, any>): void => {
    const modalStackCopy = [...modalStack];
    const previousModal = modalStackCopy.pop();

    setModal(previousModal?.key || null);
    setModalData(updatedData || previousModal?.modalData || {});
    setModalStack(modalStackCopy);
  };

  const clearModal = (): void => {
    setModal(null);
    setModalData({});
    setModalStack([]);
  };

  const updateModalData = (updatedData: Record<string, any>): void => {
    setModalData(updatedData);
  };

  return {
    modal,
    modalData,
    modalStack,
    createFromModalStack,
    setActiveModal,
    pushModal,
    popModal,
    clearModal,
    updateModalData
  };
};
