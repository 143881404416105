import { useContext } from 'react';

import * as ToasterContext from 'context/toasterContext';
import { ToasterConfig } from 'context/toasterContext';

/**
 * Re-exporting 'ToasterVariant' for eas of use;
 * eg;
 * Allows us to do:
 *
 * import {ToasterVariant, useToaster} from '../useToaster'
 *
 * instead of two different imports:
 * import {ToasterVariant} from '../ToasterContext' AND
 * import {useToaster} from '../useToaster'
 */
export const { ToasterVariant } = ToasterContext;

// Toaster options
interface ToasterOption {
  variant?: ToasterContext.ToasterVariant;
  offsetLeft?: boolean;
  keepOpen?: boolean;
}

/**
 * Hook to trigger toaster showing.
 * Sets text and options which will trigger the <Toaster> component to show that wraps the entire app.
 * @returns
 */
export const useToaster = (): {
  showToast(textInput: string | JSX.Element, optionsInput?: ToasterOption): void;
  dismissToast(): void;
} => {
  const { setToaster, isToasterOpen } = useContext(ToasterContext.default);

  const dismissToast = (): void => {
    setToaster((prevValue: ToasterConfig) => {
      return { ...prevValue, triggerHide: true };
    });
  };

  /**
   * Show toast with given text and options.
   * Replaces existing toast if it's open with fadeout/fadein animation.
   *
   * @param textInput Text or JSX content to show in the toast.
   * @param optionsInput Options for the toast.
   */
  const showToast = (textInput: string | JSX.Element, optionsInput?: ToasterOption): void => {
    const newState = {
      text: textInput,
      variant: optionsInput?.variant || ToasterContext.ToasterVariant.DEFAULT,
      offsetLeft: optionsInput?.offsetLeft,
      triggerHide: false,
      keepOpen: optionsInput?.keepOpen
    };

    if (isToasterOpen) {
      dismissToast();

      setTimeout(() => {
        setToaster(newState);
      }, 600); // 500ms for fadeout animation + 100ms before showing new toast
    } else {
      setToaster(newState);
    }
  };

  return { showToast, dismissToast };
};
