import React, { Suspense } from 'react';
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
  Navigate
} from 'react-router-dom';
import loadable from '@loadable/component';

import {
  SIGN_IN_ROUTE,
  SIGN_UP_ROUTE,
  PROFILE_ROUTE,
  USER_ROUTE,
  EVENT_ROUTE,
  EVENT_CHECKIN_ROUTE,
  CLAIM_ACCOUNT_ROUTE,
  DASHBOARD_ROUTE,
  TEAMS_ROUTE,
  EVENT_EDIT_ROUTE,
  EVENTS_LIST_ROUTE,
  EVENT_INSIGHTS_ROUTE,
  INTEGRATIONS_ROUTE,
  CUSTOM_SETTINGS_ROUTE,
  EMPLOYEE_LIST_ROUTE,
  COMMUNICATIONS_LIST_ROUTE,
  PROMOTE_CALENDAR_ROUTE,
  COMMUNICATIONS_SLACK_ROUTE,
  DISCOVER_EVENTS_ROUTE,
  ERROR_ROUTE,
  CONTENT_LIST_ROUTE,
  CONTENT_EDIT_ROUTE,
  CONTENT_INSIGHTS_ROUTE,
  CONTENT_ROUTE,
  SURVEY_TEMPLATE_LIST_ROUTE,
  SURVEY_TEMPLATE_EDIT_ROUTE,
  INSIGHTS_DASHBOARD_ROUTE,
  USER_SETTINGS_ROUTE,
  DISCOVER_COMMUNITIES_ROUTE,
  DISCOVER_CONTENTS_ROUTE
} from 'constants/routes';
import { USER_PERMISSIONS } from 'constants/enums';
import { UserProvider } from 'context/userContext';
import { ModalProvider } from 'context/modalContext';
import { QueryParamsProvider } from 'context/queryParamsContext';
import { InsightsProvider } from 'pages/dashboard/overview/insights/context/insightsContext';
import { DashboardViewProvider } from 'pages/dashboard/overview/insights/context/dashboardViewContext';
import { EventInvitationJobsProvider } from 'context/eventInvitationJobsContext';
import LoadingSpinner from 'components/common/loaders/LoadingSpinner';
import { getConfig } from 'config/baseConfig';
import Toaster from 'components/common/toaster';

import ScrollToTop from './ScrollToTop';
import RouteAccessCheck from './RouteAccessCheck';
import { AdminProtectedRoutesOutlet } from './AdminProtectedRoutesOutlet';
import DiscoverPageRouteGuard from './DiscoverPageRouteGuard';

// Error boundary
const ErrorBoundary = loadable(() => import('pages/error/ErrorBoundary'));

// Layouts
const PageLayout = loadable(() => import('layouts/PageLayout'));
const DashboardPageLayout = loadable(() => import('layouts/DashboardPageLayout'));
const SpotlightWrapperLayout = loadable(() => import('layouts/SpotlightWrapperLayout'));

// Error page
const ErrorPage = loadable(() => import('pages/error/ErrorPage'));

// Sign in/up and profile
const SignIn = loadable(() => import('pages/login/SignInUpPage'));
const TokenSignIn = loadable(() => import('pages/login/TokenSignInUpPage'));

// Employee pages
const DiscoverEventsPage = loadable(() => import('pages/discover/event/DiscoverEventsPage'));
const DiscoverContentsPage = loadable(() => import('pages/discover/content/DiscoverContentsPage'));
const DiscoverCommunitiesPage = loadable(
  () => import('pages/discover/community/DiscoverCommunitiesPage')
);
const EventPage = loadable(() => import('pages/discover/event/EventPage'));
const EventCheckinPage = loadable(() => import('pages/discover/event/EventCheckinPage'));
const ContentPage = loadable(() => import('pages/discover/content/ContentPage'));
const Profile = loadable(() => import('pages/profile/ProfilePage'));
const AccountSettingsPage = loadable(() => import('pages/AccountSettings/AccountSettingsPage'));
const NotificationSettingsPage = loadable(
  () => import('pages/AccountSettings/NotificationSettings/NotificationSettingsPage')
);
const ProfileSettingsPage = loadable(
  () => import('pages/AccountSettings/ProfileSettings/ProfileSettingsPage')
);

// Dashboard - Overview
const DashboardInsights = loadable(
  () => import('pages/dashboard/overview/insights/InsightsDashboardPage')
);
const DashboardEventEdit = loadable(() => import('pages/dashboard/overview/events/EditEventPage'));
const DashboardEventsList = loadable(() => import('pages/dashboard/overview/events/EventListPage'));
const DashboardContentEdit = loadable(
  () => import('pages/dashboard/overview/contentLibrary/EditContentPage')
);
const DashboardContentList = loadable(
  () => import('pages/dashboard/overview/contentLibrary/ContentListPage')
);
const DashboardContentInsights = loadable(
  () => import('pages/dashboard/overview/contentLibrary/ContentInsightsPage')
);
const DashboardEventInsights = loadable(
  () => import('pages/dashboard/overview/events/EventInsightsPage')
);
const DashboardEventCheckinManagerModal = loadable(
  () => import('components/dashboard/EventInsights/CheckinManagerModal/CheckinManagerModal')
);
const DashboardEventCheckinManagerPage = loadable(
  () => import('components/dashboard/EventInsights/CheckinManagerPage/CheckinManagerPage')
);
const DashboardCommunicationsList = loadable(
  () => import('pages/dashboard/overview/communications/CommunicationsListPage')
);
const DashboardPromoteCalendar = loadable(
  () => import('pages/dashboard/overview/communications/PromoteCalendarPage')
);
const DashboardAnnounceSlack = loadable(
  () => import('pages/dashboard/overview/communications/AnnounceSlackPage')
);

// Dashboard - Survey Template
const SurveyTemplatePage = loadable(() => import('pages/dashboard/overview/survey_template'));

const SurveyTemplateEditPage = loadable(
  () => import('pages/dashboard/overview/survey_template/SurveyTemplateEditPage')
);

// Dashboard - Settings
const DashboardTeamsList = loadable(() => import('pages/dashboard/settings/teams/TeamsListPage'));
const DashboardEmployeePage = loadable(
  () => import('pages/dashboard/settings/employees/DashboardEmployeePage')
);
const DashboardIntegrations = loadable(
  () => import('pages/dashboard/settings/integrations/IntegrationsPage')
);
const DashboardCustomSettings = loadable(
  () => import('pages/dashboard/settings/custom_settings/CustomSettingsPage')
);

// Other
const ZoomCallback = loadable(() => import('pages/redirects/ZoomCallbackRedirect'));

const ScrollToTopLayout = (): React.ReactElement => (
  <>
    <Outlet />
    <ScrollToTop />
  </>
);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route element={<ScrollToTopLayout />}>
      <Route element={<QueryParamsProvider />}>
        <Route element={<ModalProvider />}>
          {/* <ScrollToTop /> */}
          <Route path={`${getConfig('API_URL')}/sso/auth`} element={<SignIn key="sign-in" />} />
          <Route path="/IdPSSO/:token" element={<TokenSignIn />} />
          <Route path={SIGN_IN_ROUTE} element={<SignIn key="sign-in" />} />
          <Route path={`${CLAIM_ACCOUNT_ROUTE}/:id`} element={<SignIn key="sign-in" />} />
          <Route path={SIGN_UP_ROUTE} element={<SignIn key="sign-up" signUp />} />
          <Route path="/zoomCallback" element={<ZoomCallback />} />
          <Route element={<UserProvider />}>
            <Route element={<SpotlightWrapperLayout />}>
              {/* Regular employee routes */}

              <Route element={<PageLayout />}>
                {/* Placing the ErrorBoundary under PageLayout route, so the error component renders inside the layout */}
                <Route errorElement={<ErrorBoundary />}>
                  <Route path={PROFILE_ROUTE} element={<Profile />} />
                  <Route path={USER_SETTINGS_ROUTE} element={<AccountSettingsPage />}>
                    {/* Redirect /account-settings to /account-settings/notifications */}
                    <Route path="" element={<Navigate to="notifications" replace />} />
                    <Route path="notifications" element={<NotificationSettingsPage />} />
                    <Route path="profile" element={<ProfileSettingsPage />} />
                  </Route>

                  <Route path={`${USER_ROUTE}/:id`} element={<Profile publicProfile />} />
                  <Route path={`${EVENT_ROUTE}/:id`} element={<EventPage />} />
                  <Route path={`${EVENT_CHECKIN_ROUTE}/:id`} element={<EventCheckinPage />} />
                  <Route path={`${CONTENT_ROUTE}/:id`} element={<ContentPage />} />
                  <Route path={DISCOVER_EVENTS_ROUTE} element={<DiscoverEventsPage />} />
                  {/* Protect Discover Contents Route */}
                  <Route
                    element={
                      <DiscoverPageRouteGuard
                        condition={(user): boolean => !!user?.company.hasActiveContent}
                      />
                    }
                  >
                    <Route path={DISCOVER_CONTENTS_ROUTE} element={<DiscoverContentsPage />} />
                  </Route>

                  {/* Protect Discover Communities Route */}
                  <Route
                    element={
                      <DiscoverPageRouteGuard
                        condition={(user): boolean => !!user?.company.hasCommunities}
                      />
                    }
                  >
                    <Route
                      path={DISCOVER_COMMUNITIES_ROUTE}
                      element={<DiscoverCommunitiesPage />}
                    />
                  </Route>
                </Route>

                {/* Error route */}
                <Route path={ERROR_ROUTE} element={<ErrorPage />} />
                <Route path="*" element={<ErrorPage />} />
              </Route>
              <Route element={<AdminProtectedRoutesOutlet />}>
                {/* Admin routes with nav */}
                <Route path={DASHBOARD_ROUTE} element={<DashboardPageLayout />}>
                  {/* we need to load in the dashboard view before anything else */}
                  <Route element={<DashboardViewProvider />}>
                    <Route element={<InsightsProvider />}>
                      <Route path={INSIGHTS_DASHBOARD_ROUTE} element={<DashboardInsights />} />
                    </Route>
                  </Route>
                  <Route path={EVENTS_LIST_ROUTE} element={<DashboardEventsList />} />
                  <Route path={CONTENT_LIST_ROUTE} element={<DashboardContentList />} />
                  <Route path={EVENT_INSIGHTS_ROUTE} element={<DashboardEventInsights />}>
                    {/* TODO: deprecate old checkin modal */}
                    <Route path="checkin" element={<DashboardEventCheckinManagerModal />} />
                    <Route
                      path={'checkin/:sessionId'}
                      element={<DashboardEventCheckinManagerModal />}
                    />
                  </Route>
                  <Route path={CONTENT_INSIGHTS_ROUTE} element={<DashboardContentInsights />} />
                  <Route path={SURVEY_TEMPLATE_LIST_ROUTE} element={<SurveyTemplatePage />} />
                  <Route
                    path={COMMUNICATIONS_LIST_ROUTE}
                    element={<DashboardCommunicationsList />}
                  />
                  <Route
                    path={TEAMS_ROUTE}
                    element={
                      <RouteAccessCheck permissions={[USER_PERMISSIONS.ORGANIZATION_WRITE]} />
                    }
                  >
                    <Route path={TEAMS_ROUTE} element={<DashboardTeamsList />} />
                  </Route>
                  <Route path={INTEGRATIONS_ROUTE} element={<DashboardIntegrations />} />
                  <Route path={CUSTOM_SETTINGS_ROUTE} element={<DashboardCustomSettings />} />
                  <Route
                    path={EMPLOYEE_LIST_ROUTE}
                    element={<RouteAccessCheck permissions={[USER_PERMISSIONS.TEAM_ADMIN_WRITE]} />}
                  >
                    <Route path={EMPLOYEE_LIST_ROUTE} element={<DashboardEmployeePage />} />
                  </Route>
                </Route>
                {/* Admin routes without nav */}
                <Route
                  path={`${EVENT_INSIGHTS_ROUTE}/checkin-manager`}
                  element={<DashboardEventCheckinManagerPage />}
                />
                <Route
                  path={`${EVENT_INSIGHTS_ROUTE}/checkin-manager/:sessionId`}
                  element={<DashboardEventCheckinManagerPage />}
                />
                <Route path={`${EVENT_EDIT_ROUTE}/:id`}>
                  <Route path={`${EVENT_EDIT_ROUTE}/:id`} element={<DashboardEventEdit />} />
                  <Route path={`${EVENT_EDIT_ROUTE}/:id/:step`} element={<DashboardEventEdit />} />
                </Route>
                <Route path={`${CONTENT_EDIT_ROUTE}/:id`}>
                  <Route path={`${CONTENT_EDIT_ROUTE}/:id`} element={<DashboardContentEdit />} />
                  <Route
                    path={`${CONTENT_EDIT_ROUTE}/:id/:step`}
                    element={<DashboardContentEdit />}
                  />
                </Route>

                <Route path={`${SURVEY_TEMPLATE_EDIT_ROUTE}/:id`}>
                  <Route
                    path={`${SURVEY_TEMPLATE_EDIT_ROUTE}/:id`}
                    element={<SurveyTemplateEditPage />}
                  />
                  <Route
                    path={`${SURVEY_TEMPLATE_EDIT_ROUTE}/:id/:step`}
                    element={<SurveyTemplateEditPage />}
                  />
                </Route>
                <Route
                  path={PROMOTE_CALENDAR_ROUTE}
                  element={
                    <RouteAccessCheck
                      permissions={[
                        USER_PERMISSIONS.PROMOTION_WRITE_ALL,
                        USER_PERMISSIONS.PROMOTION_WRITE_OWN
                      ]}
                    />
                  }
                >
                  <Route
                    path={`${PROMOTE_CALENDAR_ROUTE}/:promotionId`}
                    element={<DashboardPromoteCalendar />}
                  />
                  <Route path={PROMOTE_CALENDAR_ROUTE} element={<DashboardPromoteCalendar />} />
                </Route>
                <Route
                  path={COMMUNICATIONS_SLACK_ROUTE}
                  element={
                    <RouteAccessCheck
                      permissions={[
                        USER_PERMISSIONS.PROMOTION_WRITE_ALL,
                        USER_PERMISSIONS.PROMOTION_WRITE_OWN
                      ]}
                    />
                  }
                >
                  <Route
                    path={`${COMMUNICATIONS_SLACK_ROUTE}/:promotionId`}
                    element={<DashboardAnnounceSlack />}
                  />
                  <Route path={COMMUNICATIONS_SLACK_ROUTE} element={<DashboardAnnounceSlack />} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Route>
  )
);

const AllRoutes = (): JSX.Element => (
  <Toaster>
    <Suspense fallback={<LoadingSpinner fullscreen />}>
      <EventInvitationJobsProvider>
        <RouterProvider router={router} />
      </EventInvitationJobsProvider>
    </Suspense>
  </Toaster>
);

export default AllRoutes;
