import { format } from 'date-fns';

import { DateRange } from './dates';

export enum Timeframe {
  thisWeek = 'thisWeek',
  lastWeek = 'lastWeek',
  thisMonth = 'thisMonth',
  lastMonth = 'lastMonth',
  last3Months = 'last3Months',
  last6Months = 'last6Months',
  thisYear = 'thisYear',
  lastYear = 'lastYear'
  // TODO: needs more work in order to be able to handle all time
  // this requires being able to pass the date from the backend
  // allTime = 'allTime'
}

interface Preset {
  name: Timeframe;
  label: string;
}

export const PRESETS: Preset[] = [
  { name: Timeframe.thisWeek, label: 'This week' },
  { name: Timeframe.lastWeek, label: 'Last week' },
  { name: Timeframe.thisMonth, label: 'This month' },
  { name: Timeframe.lastMonth, label: 'Last month' },
  { name: Timeframe.last3Months, label: 'Last 3 months' },
  { name: Timeframe.last6Months, label: 'Last 6 months' },
  { name: Timeframe.thisYear, label: 'This year' },
  { name: Timeframe.lastYear, label: 'Last year' }
  // { name: Timeframe.allTime, label: 'All time' }
];

export const getPresetLabel = (presetName?: string | null, range?: DateRange): string => {
  if (!presetName) {
    if (range) {
      const formattedFrom = format(range.from, 'MMM d, yyyy');
      const formattedTo = format(range.to, 'MMM d, yyyy');
      return `${formattedFrom} - ${formattedTo}`;
    }
    return 'Custom';
  }

  const preset = PRESETS.find(({ name }) => name === presetName);
  if (!preset) {
    if (range) {
      const formattedFrom = format(range.from, 'MMM d, yyyy');
      const formattedTo = format(range.to, 'MMM d, yyyy');
      return `${formattedFrom} - ${formattedTo}`;
    }
    return 'Custom';
  }

  return preset.label;
};

export const getPresetRange = (presetName: string): DateRange => {
  const preset = PRESETS.find(({ name }) => name === presetName);
  if (!preset) throw new Error(`Unknown date range preset: ${presetName}`);
  const from = new Date();
  const to = new Date();
  const first = from.getDate() - from.getDay();

  switch (preset.name) {
    case Timeframe.thisWeek:
      from.setDate(first);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.lastWeek:
      from.setDate(from.getDate() - 7 - from.getDay());
      to.setDate(to.getDate() - to.getDay() - 1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.thisMonth:
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.lastMonth:
      from.setMonth(from.getMonth() - 1);
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setDate(0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.last3Months:
      from.setMonth(from.getMonth() - 3);
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setDate(0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.last6Months:
      from.setMonth(from.getMonth() - 6);
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setDate(0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.thisYear:
      from.setMonth(0);
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      to.setDate(0);
      to.setHours(23, 59, 59, 999);
      break;
    case Timeframe.lastYear:
      from.setMonth(0);
      from.setDate(1);
      from.setHours(0, 0, 0, 0);
      from.setFullYear(from.getFullYear() - 1);
      to.setFullYear(to.getFullYear() - 1);
      to.setMonth(11);
      to.setDate(31);
      to.setHours(23, 59, 59, 999);
      break;
    // case Timeframe.allTime:
    //   // TODO: Use date from backend for when companies first event was
    //   from.setDate(1);
    //   from.setHours(0, 0, 0, 0);
    //   to.setDate(0);
    //   to.setHours(23, 59, 59, 999);
    //   break;
    default:
      break;
  }

  return { from, to };
};
