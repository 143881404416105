import React, { createContext } from 'react';

export enum ToasterVariant {
  DEFAULT = 'default',
  ERROR = 'ERROR'
}

export interface ToasterConfig {
  text: string | JSX.Element | undefined;
  variant: ToasterVariant | undefined;
  triggerHide: boolean;
  offsetLeft?: boolean;
  keepOpen?: boolean;
}

interface ToasterContextType {
  toaster: ToasterConfig;
  setToaster: React.Dispatch<React.SetStateAction<ToasterConfig>>;
  isToasterOpen: boolean;
}
const ToasterContext = createContext<ToasterContextType>({
  toaster: {
    text: '',
    variant: ToasterVariant.DEFAULT,
    triggerHide: false,
    offsetLeft: false,
    keepOpen: false
  },
  setToaster: () => undefined,
  isToasterOpen: false
});

export default ToasterContext;
