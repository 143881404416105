import React, { FunctionComponent, useContext } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import UserContext from 'context/userContext';
import { GQL_ERRORS } from 'graphql/apollo';
import { ERROR_ROUTE } from 'constants/routes';

const RouteAccessCheck: FunctionComponent<React.PropsWithChildren<{ permissions: string[] }>> = ({
  permissions
}) => {
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();

  const userPermissions = currentUser?.permissions;
  if (userPermissions) {
    const userHasOneCorrectPermission = permissions.some((permission) =>
      userPermissions.includes(permission)
    );

    if (userHasOneCorrectPermission) {
      return <Outlet />;
    }
  }

  navigate({ pathname: ERROR_ROUTE, search: `?code=${GQL_ERRORS.ACCESS_DENIED}` });
  return <></>;
};

export default RouteAccessCheck;
