import React, { createContext } from 'react';
import { Outlet } from 'react-router-dom';

import useQueryParams from 'hooks/useQueryParams';

export type QueryParamsState = ReturnType<typeof useQueryParams>;

const QueryParamsContext = createContext<QueryParamsState>({} as QueryParamsState);

/**
 * This context ensures that all query params go through the same hook.
 * Otherwise, if doing writes/deletes to the query params from multiple places,
 * it will result in race conditions.
 */
export function QueryParamsProvider(): JSX.Element {
  const { searchParams, setQueryParam } = useQueryParams();

  return (
    <QueryParamsContext.Provider value={{ searchParams, setQueryParam }}>
      <Outlet />
    </QueryParamsContext.Provider>
  );
}

export default QueryParamsContext;
