import React, { useContext } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import UserContext from 'context/userContext';
import { DISCOVER_EVENTS_ROUTE } from 'constants/routes';

interface DiscoverPageRouteGuardProps {
  condition: (user: any) => boolean;
  redirectTo?: string;
}

const DiscoverPageRouteGuard: React.FC<DiscoverPageRouteGuardProps> = ({
  condition,
  redirectTo = DISCOVER_EVENTS_ROUTE
}) => {
  const currentUser = useContext(UserContext);

  // If condition is not met, redirect to the specified route (default to DISCOVER_EVENTS_ROUTE)
  if (!currentUser || !condition(currentUser)) {
    return <Navigate to={redirectTo} replace />;
  }

  // If condition is met, render the child route
  return <Outlet />;
};

export default DiscoverPageRouteGuard;
