import React, { createContext, FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';

import { useModal, ModalState } from 'hooks/useModal';

export interface ModalContextState {
  modalState?: ModalState;
}

const ModalContext = createContext<ModalContextState>({});

export const ModalProvider: FunctionComponent<React.PropsWithChildren<unknown>> = () => {
  const modalState = useModal();

  return (
    <ModalContext.Provider value={{ modalState }}>
      <Outlet />
    </ModalContext.Provider>
  );
};

export default ModalContext;
