export interface DateRange {
  from: Date;
  to: Date;
}

export const getDateAdjustedForTimezone = (dateInput: Date | string): Date => {
  if (typeof dateInput === 'string') {
    // Split a date string in the format '2024-05-17T04:00:00+00:00' to get year, month, and day parts
    const [year, month, day] = dateInput.split('T')[0].split('-').map(Number);

    // Create a new Date object using the local timezone
    // Note: Month is 0-indexed, so subtract 1 from the month part
    const date = new Date(year, month - 1, day);

    return date;
  }

  // If dateInput is already a Date object, return it directly
  return dateInput;
};

// Helper function to check if two date ranges are equal
export const areRangesEqual = (a?: DateRange, b?: DateRange): boolean => {
  if (!a || !b) {
    return a === b;
  }

  if (!a.from || !b.from) {
    return a.from === b.from;
  }

  return (
    a.from.getTime() === b.from.getTime() && (!a.to || !b.to || a.to.getTime() === b.to.getTime())
  );
};
