import React, { useContext, useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';

import UserContext from 'context/userContext';
import { GQL_ERRORS } from 'graphql/apollo';
import { ERROR_ROUTE } from 'constants/routes';
import { USER_ROLES } from 'constants/enums';

const ADMIN_ROLES: Set<string> = new Set([
  USER_ROLES.COMPANY_TEAM_ADMIN,
  USER_ROLES.COMPANY_SUPER_ADMIN,
  USER_ROLES.EPOCH_ADMIN
]);

const AdminProtectedRoutesOutlet: React.FC = () => {
  const currentUser = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!ADMIN_ROLES.has(currentUser?.userRole || '')) {
      navigate({
        pathname: ERROR_ROUTE,
        search: `?code=${GQL_ERRORS.ACCESS_DENIED}`
      });
    }
  }, [currentUser]);

  return <Outlet />;
};

export { AdminProtectedRoutesOutlet };
