import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useRef, useState } from 'react';

import {
  INSIGHTS_DASHBOARD_METRICS,
  InsightsDashboardMetricsResult
} from 'graphql/queries/insights/insights.query';
import { Timeframe } from '@epoch-components/DateRangePicker/utils/presets';

import { getComparisonMetricsDates } from './utils';

interface InsightsFilters {
  startDate: Date | null;
  endDate: Date | null;
  timeframe: Timeframe | null;
  teams: string[];
  categories: string[];
  locations: string[];
}

export interface InsightsState {
  currentMetrics?: InsightsDashboardMetricsResult['insightsMetrics'];
  pastMetrics?: InsightsDashboardMetricsResult['insightsMetrics'];
  comparisonText?: string;
  loading?: boolean;
  refetch: () => void;
}

export default function useInsights({
  startDate,
  endDate,
  timeframe,
  teams,
  categories,
  locations
}: InsightsFilters): InsightsState {
  const comparisonDates = useMemo(
    () => getComparisonMetricsDates(startDate, endDate, timeframe),
    [startDate, endDate]
  );

  const comparisonText = useMemo(() => {
    if (!comparisonDates.startDate || !comparisonDates.endDate) {
      return undefined;
    }

    const format = new Intl.DateTimeFormat('en-US', {
      month: 'short',
      day: 'numeric',
      year: 'numeric'
    });

    return `vs. ${format.format(comparisonDates.startDate)} - ${format.format(
      comparisonDates.endDate
    )}`;
  }, [comparisonDates.startDate, comparisonDates.endDate]);

  const {
    data: currentMetricsData,
    loading: isMetricsLoading,
    refetch: refetchMetrics
  } = useQuery<InsightsDashboardMetricsResult>(INSIGHTS_DASHBOARD_METRICS, {
    fetchPolicy: 'network-only',
    variables: {
      startDate,
      endDate,
      teams,
      locations,
      categories
    },
    // we don't want to fetch data if startDate and endDate are not set
    skip: !startDate && !endDate
  });

  const {
    data: pastMetricsData,
    loading: isComparisonMetricsLoading,
    refetch: refetchComparisonMetrics
  } = useQuery<InsightsDashboardMetricsResult>(INSIGHTS_DASHBOARD_METRICS, {
    fetchPolicy: 'network-only',
    variables: {
      startDate: comparisonDates.startDate,
      endDate: comparisonDates.endDate,
      teams,
      locations,
      categories
    },
    // we don't want to fetch data if startDate and endDate are not set
    skip: !startDate && !endDate
  });

  const [loading, setLoading] = useState(false);
  const [currentMetrics, setCurrentMetrics] = useState<
    InsightsDashboardMetricsResult['insightsMetrics'] | undefined
  >(currentMetricsData?.insightsMetrics);
  const [pastMetrics, setPastMetrics] = useState<
    InsightsDashboardMetricsResult['insightsMetrics'] | undefined
  >(pastMetricsData?.insightsMetrics);

  const isFirstRender = useRef(true);

  useEffect(() => {
    let timeout: NodeJS.Timeout | undefined;

    if (isMetricsLoading || isComparisonMetricsLoading) {
      if (isFirstRender.current) {
        setLoading(true);
      } else {
        // Set loading state to true after 1 second
        timeout = setTimeout(() => {
          setLoading(true);
        }, 1000);
      }
    } else {
      // Clear timeout and set loading to false immediately when queryLoading is false
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
      setLoading(false);
      // Update metrics only when queryLoading is false
      if (currentMetricsData?.insightsMetrics) {
        setCurrentMetrics(currentMetricsData.insightsMetrics);
      }

      if (pastMetricsData?.insightsMetrics) {
        setPastMetrics(pastMetricsData.insightsMetrics);
      }
    }

    isFirstRender.current = false;

    // Cleanup timeout on component unmount or when queryLoading changes
    return () => {
      if (timeout !== undefined) {
        clearTimeout(timeout);
      }
    };
  }, [
    isMetricsLoading,
    isComparisonMetricsLoading,
    JSON.stringify(currentMetricsData?.insightsMetrics),
    JSON.stringify(pastMetricsData?.insightsMetrics)
  ]);

  const handleRefetch = (): void => {
    refetchMetrics();
    refetchComparisonMetrics();
  };

  return { currentMetrics, pastMetrics, comparisonText, loading, refetch: handleRefetch };
}
