import React, { useContext } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';

import UserContext from 'context/userContext';

const useFeatureFlag = (flagName, expectedFlagValue = 'true') => {
  const currentUser = useContext(UserContext);
  const queryParams = queryString.parse(useLocation().search) || {};
  // If flag is attached as a query param, add it to the user feature flags
  if (currentUser && queryParams[flagName] === expectedFlagValue) {
    currentUser.featureFlags = [...(currentUser.featureFlags || []), flagName];
  }

  return currentUser?.featureFlags?.includes(flagName);
};

export default useFeatureFlag;
