import React, { FunctionComponent } from 'react';
import { ApolloProvider } from '@apollo/client';
import 'react-tooltip/dist/react-tooltip.css';

import './index.css';

import client from 'graphql/apollo';
import 'assets/images/favicon.ico';
import Routes from 'routes';
import { TooltipProvider } from '@epoch-ui/components/Tooltip';

const App: FunctionComponent<React.PropsWithChildren<unknown>> = () => (
  <ApolloProvider client={client}>
    <TooltipProvider delayDuration={200}>
      <Routes />
    </TooltipProvider>
  </ApolloProvider>
);

export default App;
