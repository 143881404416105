import styled, { css, keyframes } from 'styled-components';

import { ERROR_RED, DARK_BLACK } from 'constants/colours';

const fadeIn = keyframes`
  from {
    transform: translateY(100%);
    opacity: 0;
  }

  to {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  from {
    opacity: 1;
  }

  to {
    transform: translateY(100%);
    opacity: 0;
  }
`;

/**
 * Our own toaster container:
 */
export const Notify = styled.div<{ offSetLeft?: boolean }>(
  ({ offSetLeft }) => css`
    display: flex;
    align-items: center;
    position: fixed;
    margin: 0 auto;
    top: 16px;
    left: 50%;
    transform: ${offSetLeft ? 'translate(-55%, 0)' : 'translate(-50%, 0)'};
    z-index: 2000;
    button {
      color: white;
      text-decoration: underline;
    }
    @media (max-width: 1020px) {
      left: 50%;
      transform: translate(-50%, 0);
    }
  `
);

/**
 * Main container + animation
 */
export const NotifyAnimationContainer = styled.div<{ error?: boolean; out: boolean }>(
  ({ error, out }) => css`
    padding: 12px 16px;
    border-radius: 4px;
    background-color: ${error ? ERROR_RED : DARK_BLACK};
    display: flex;
    align-items: flex-start;
    color: white;
    visibility: ${out ? 'hidden' : 'visible'};
    animation: ${out ? fadeOut : fadeIn} 0.1s linear;
    transition: visibility 0.1s linear;
    max-width: 600px;
  `
);

export const CloseButtonWrapper = styled.button`
  border: none;
  background: none;
  cursor: pointer;
  margin-left: 8px;
  padding: 0;
`;
